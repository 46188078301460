import { HorarioTallerFront, Taller } from '../../types/Taller'
import { SeoData } from '../../types/Seo'
import { graphql } from 'gatsby'
import React from 'react'
import { t } from '../../i18n'
import route from '../../utils/route'
import { BreadCrumbItems } from '../../components/breadCrumb/LocalizedBreadCrumb'
import Layout from '../../components/Layout'
import styles from '../talleres/talleres.module.scss'
import Directorio from '../../components/Talleres/Directorio'

type Apertura = 'mediodia' | 'sabado' | 'moto' | 'renting'

interface Props {
  data: PageData
  pageContext: {
    url: string
    apertura: Apertura
  }
}

type TallerFull = Taller & HorarioTallerFront

const buildAperturaFilter = (apertura: Apertura) => {
  switch (apertura) {
    case 'mediodia':
      return (t: TallerFull) => !t.horario_lunvie_t_front
    case 'sabado':
      return (t: TallerFull) => !!t.horario_sab_m_front
    case 'moto':
      return (t: TallerFull) => !!t.moto
    case 'renting':
      return (t: TallerFull) => !!t.renting
  }
  throw new Error(`Tipo de apertura no reconocido: ${apertura}`)
}

const PageTemplate = ({ data, pageContext }: Props) => {
  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
  ] as BreadCrumbItems

  breadCrumItems.push({
    display: t('bread_crumb.talleres-mecanicos'),
  })
  const talleres = data.allTalleres.nodes.filter(
    buildAperturaFilter(pageContext.apertura)
  )
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={breadCrumItems}>
      <section className={styles.talleres}>
        <React.Fragment>
          <h1 className={styles.title_list}>
            {t(`talleres_abiertos.title.${pageContext.apertura}`, {
              web: 'Rodi',
            })}
          </h1>
          <Directorio
            talleres={talleres}
            areaToShow={''}
            applyStyles={2}
            reverseBarcelonaCiudad={true}
          />
        </React.Fragment>
      </section>
    </Layout>
  )
}
export default PageTemplate

interface PageData {
  allTalleres: {
    nodes: TallerFull[]
  }
  seoData: SeoData
}

export const pageQuery = graphql`
  query talleresAbiertosQuery($url: String!) {
    allTalleres(sort: { order: ASC, fields: provincia }) {
      nodes {
        direccion
        cpostal
        provincia
        poblacion
        codigo_taller
        alias
        empresa
        activo
        telefono
        slug
        moto
        renting
        region
        info_directorio_ca
        info_directorio_es
        info_directorio_pt
        front_comentario
        horario_dom_t_front
        horario_dom_m_front
        horario_lunvie_t_front
        horario_lunvie_m_front
        horario_sab_m_front
        horario_sab_t_front
      }
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
