const { slugify } = require('../utils/slugify')

function inArea(taller, area) {
  // Es el "area" global (todos los talleres)
  if (!area.provincia) {
    return true
  }

  if (area.provincia !== taller.provincia) {
    return false
  }

  // Es un area sin división por poblacion
  if (!area.poblacion) {
    return true
  }

  if ('include' in area.poblacion) {
    return taller.poblacion.trim().startsWith(area.poblacion.include)
  } else {
    return !taller.poblacion.trim().startsWith(area.poblacion.exclude)
  }
}

function whichArea(taller) {
  return Areas.filter(a => !!a.area).find(a => inArea(taller, a))
}

function displayName(area) {
  return `areas.${slugify(area.area)}`
}

function getCoordinates(area) {
  const { lat, lon } = Areas.find(a => a.area === area)
  return { lat, lon }
}

module.exports = { inArea, whichArea, displayName, getCoordinates }
