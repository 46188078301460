const { slugify } = require('../../../src/common/utils/slugify')

const Areas = [
  { area: '' },
  {
    area: 'CASTELO BRANCO',
    provincia: 'CASTELO BRANCO',
    slug: 'castelo-branco',
    lat: '39.80665',
    lon: '-7.517676',
  },
  {
    area: 'GUARDA',
    provincia: 'GUARDA',
    slug: 'guarda',
    lat: '40.5527644',
    lon: '-7.2470015',
  },
  {
    area: 'PORTO',
    provincia: 'PORTO',
    slug: 'porto',
    lat: '41.2118994',
    lon: '-8.7005455',
  },
]

function inArea(taller, area) {
  // Es el "area" global (todos los talleres)
  if (!area.provincia) {
    return true
  }

  if (area.provincia !== taller.provincia) {
    return false
  }

  // Es un area sin división por poblacion
  if (!area.poblacion) {
    return true
  }

  if ('include' in area.poblacion) {
    return taller.poblacion.trim().startsWith(area.poblacion.include)
  } else {
    return !taller.poblacion.trim().startsWith(area.poblacion.exclude)
  }
}

function whichArea(taller) {
  return Areas.filter(a => !!a.area).find(a => inArea(taller, a))
}

function displayName(area) {
  return `areas.${slugify(area.area)}`
}

function getCoordinates(area) {
  const { lat, lon } = Areas.find(a => a.area === area)
  return { lat, lon }
}

module.exports = { Areas, inArea, whichArea, displayName, getCoordinates }
