import { Link } from 'gatsby'
import React, { useState } from 'react'
import Down from '../../assets/arrow.svg'
import { Area, displayName, inArea } from '../../build/Areas.js'
import { t, textWithLocale } from '../../i18n'
import { Taller } from '../../types/Taller'
import route from '../../utils/route'
import { slugify } from '../../utils/slugify.js'
import styles from './directorio.module.scss'
// need to import this with require because the file is exported as module.exports
const { Areas } = require('../../../specific/build/Areas')

interface TitleListProps {
  area: Area
  toggleSection: () => void
  areaToShow: string
}

const TitleList = ({ area, toggleSection, areaToShow }: TitleListProps) => {
  return (
    <React.Fragment>
      {areaToShow ? (
        <h2 onClick={toggleSection} className={styles.titleMobile}>
          {t(displayName(area))}
          <Down />
        </h2>
      ) : (
        <h3 onClick={toggleSection} className={styles.titleMobile}>
          {t(displayName(area))}
          <Down />
        </h3>
      )}
      <Link
        to={
          process.env.GATSBY_WEB === 'rodimotor'
            ? ''
            : route('talleres.area', {
                area: slugify(t(`talleres.${area.slug}`)),
              })
        }>
        {areaToShow ? (
          <h2 onClick={toggleSection} className={styles.titleDesktop}>
            {t(displayName(area))}
            <Down />
          </h2>
        ) : (
          <h3 onClick={toggleSection} className={styles.titleDesktop}>
            {t(displayName(area))}
            <Down />
          </h3>
        )}
      </Link>
    </React.Fragment>
  )
}

interface TalleresListProps {
  talleres: Taller[]
}
const TalleresList = ({ talleres }: TalleresListProps) => (
  <ul className={styles.talleres}>
    {talleres
      .sort((a, b) => (a.alias > b.alias ? 1 : -1))
      .map((taller) => (
        <li key={taller.slug}>
          <Link
            to={route('talleres.taller', {
              provincia: slugify(taller.provincia),
              taller: slugify(taller.slug),
            })}>
            <span>{taller.alias}</span>
          </Link>
          {taller.info_directorio_es || taller.info_directorio_ca ? (
            <div
              className={styles.info_directorio}
              dangerouslySetInnerHTML={{
                __html: taller[textWithLocale('info_directorio')],
              }}></div>
          ) : (
            ''
          )}
        </li>
      ))}
  </ul>
)

interface AreaProps {
  area: Area
  talleres: Taller[]
  toggleSection: () => void
  expanded: boolean
  areaToShow: string
}
const AreaSection = ({
  area,
  talleres,
  toggleSection,
  expanded,
  areaToShow,
}: AreaProps) => (
  <section
    key={area.area}
    className={`${styles.area} ${expanded ? styles.expanded : ''}`}>
    <TitleList
      toggleSection={toggleSection}
      area={area}
      areaToShow={areaToShow}
    />
    <TalleresList talleres={talleres} />
  </section>
)

interface Props {
  talleres: Taller[]
  areaToShow: string
  applyStyles?: number
  reverseBarcelonaCiudad?: boolean
}
export default function Directorio({
  talleres,
  areaToShow,
  applyStyles,
  reverseBarcelonaCiudad,
}: Props) {
  const [menu, setMenu] = useState(null)
  const toggleSection = (key: string) => {
    setMenu(key === menu ? null : key)
  }
  const showArea = Areas.find((a: Area) => a.area === areaToShow) as Area
  const data = Areas.filter((area: Area) => {
    // For Barcelona we have an exception as the city
    // is separated from the area
    if (showArea.area === 'BARCELONA CIUDAD') {
      return !!area.area && showArea.area === area.area
    }
    return (
      !!area.area && (showArea.provincia === area.provincia || !showArea.area)
    )
  })
    .map(
      (area: Area) =>
        [area, talleres.filter((t) => inArea(t, area))] as [Area, Taller[]]
    )
    .filter(([_, talleres]) => talleres.length > 0) as [Area, Taller[]][]
  if (showArea.area === 'BARCELONA' || reverseBarcelonaCiudad) {
    // ponemos barcelona ciudad la primera
    if (
      data.length > 1 &&
      data[0][0].area === 'BARCELONA' &&
      data[1][0].area === 'BARCELONA CIUDAD'
    ) {
      data.unshift(data[1])
      data.splice(2, 1)
    }
  }

  const style = applyStyles || data.length

  return (
    <div
      className={`${styles.wrapper} ${
        style === 1
          ? styles.single
          : style === 2
          ? styles.double
          : styles.multiple
      }`}>
      {data.map(([area, talleres]) => (
        <AreaSection
          area={area}
          talleres={talleres}
          key={area.area}
          toggleSection={() => toggleSection(area.area)}
          expanded={menu === area.area}
          areaToShow={areaToShow}
        />
      ))}
    </div>
  )
}
