function normalize(str) {
  try {
    return str.normalize('NFD')
  } catch {}
  return str
}

module.exports = {
  normalize,
}
