const { normalize } = require('./charactersEscaping')

function slugify(text) {
  return normalize(text)
    .toLowerCase()
    .replace(/[\s+]/g, '-')
    .replace(/[^0-9a-z_-]/g, '')
}

module.exports = {
  slugify,
}
